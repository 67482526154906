import React from 'react'
import About from '../components/About'
import Nav from '../components/Header'
import Footer from '../components/Footer'
import Team from '../components/Team'

export default function AboutPage() {
  return (
    <div>
      <Nav />
      <About />
      <Team />
      <Footer />
    </div>
  )
}
