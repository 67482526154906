import React from 'react'
import Support from '../components/Support'
import Nav from '../components/Header'
import Footer from '../components/Footer'

export default function SupportPage() {
  return (
    <div>
        <Nav />
      <Support />
      <Footer />
    </div>
  )
}
