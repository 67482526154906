import React from 'react'
import Projects from '../components/Projects'
import Nav from '../components/Header'
import Footer from '../components/Footer'
// import Footer from '../components/Footer'

export default function ProjectPage() {
  return (
    <div>
      <Nav />
      <Projects />
      <Footer />
    </div>
  )
}
