import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Nav from "../components/Header";

export default function ContactUs() {
  return (
    <div>
      {/* <Nav /> */}
      <Contact />
      {/* <Footer /> */}
    </div>
  )
}
