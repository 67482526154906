import React, { useState, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import Loader from './components/Loader';
import router from './route';

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time (remove this in your actual application)
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);
                         
    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);
  return (

    <div>
      {loading ? (
        <Loader />
      ) : (
        // Your main content goes here once loading is complete
        <RouterProvider router={router} />
      )}
    </div>
  );
}
